import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { FaGoogle } from "react-icons/fa";
import { SiHuawei } from "react-icons/si";
import { SiAmazonalexa } from "react-icons/si";
import { SiMan } from "react-icons/si";
import Grid from "@mui/material/Grid2";
import WiatesLogo from "../assets/images/wiates-transparant-logo.svg"
import './Globalstylingfonts.css'

// Placeholder for logos. Replace with actual image paths or use MUI's SvgIcon for vector logos
const logos = [
  { src: <FaGoogle />, alt: 'Google' },
  { src: <SiMan />, alt: 'MAN' },
  { src: <SiHuawei />, alt: 'Huawei' },
  { src: <SiAmazonalexa />, alt: 'Alexa' },
];

const Greatmindslogos: React.FC = () => {
  return (
    <Box sx={{ backgroundColor: '#0E0E0E', color: 'white', py: 5 }}>
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center">
          {/* Left section: Text and logos */}
          <Grid size={{ xs: 12, md: 6 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                sx={{
                  fontFamily: 'Circular STD Bold',
                  color: 'rgba(255, 255, 255, 0.9)',
                  mb: 3,
                }}
              >
                We work with great minds like you
              </Typography>
              
              <Grid container spacing={3} sx={{ mt: 2 }}>
                {logos.map((logo, index) => (
                  <Grid key={index} size={{ xs: 3 }} sx={{ fontSize: '40px', display: 'flex', justifyContent: 'center' }}>
                    {logo.src}
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>

          {/* Right section: Large background logo */}
          <Grid
            size={{ xs: 12, md: 6 }}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              src={WiatesLogo}
              alt="Wiates Logo"
              sx={{
                width: '30%',
                height: '30%'
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Greatmindslogos;
