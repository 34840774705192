import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Container
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import HomeIcon from '@mui/icons-material/Home';
import { CiMobile3 } from "react-icons/ci";
import { LuBrainCircuit } from "react-icons/lu";
import { MdCloudQueue } from "react-icons/md";
import './Globalstylingfonts.css'

// Sample data array with icons
const cardItems = [
  {
    id: 1,
    title: "Experienced Team",
    description:
      "Our skilled professionals bring years of industry experience, ensuring high-quality solutions tailored to your unique needs.",
    icon: <CiMobile3 style={{ fontSize: "40px", color: "#ffffff" }} />,
  },
  {
    id: 2,
    title: "Innovative Solutions",
    description:
      "We leverage the latest technologies and best practices to deliver cutting-edge solutions that drive efficiency and growth.",
    icon: <HomeIcon style={{ fontSize: "40px", color: "#ffffff" }} />,
  },
  {
    id: 3,
    title: "Client-Centric Approach",
    description:
      "Your success is our priority. We collaborate closely with you to understand your goals and provide personalized support throughout the project.",
    icon: <LuBrainCircuit style={{ fontSize: "40px", color: "#ffffff" }} />,
  },
  {
    id: 4,
    title: "Proven Track Record",
    description:
      "With a portfolio of successful projects and satisfied clients, our results speak for themselves, demonstrating our commitment to excellence.",
    icon: <MdCloudQueue style={{ fontSize: "40px", color: "#ffffff" }} />,
  }
];

const Chooseus = () => {
  return (
    <div style={{ color: 'white', paddingTop: '6%',paddingBottom: '6%', position: 'relative'}}>
      {/* Subtle Plus Symbol Centered */}
      <div style={{
        position: 'absolute',
        top: '59%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        width: '200px',
        height: '200px',
        // background: 'radial-gradient(circle, #1FBEC6 0%, #06515B 0%, #000000 35%, #000000 0%)'
      }}>
        {/* Plus symbol */}
        <div style={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}>
          <div style={{
            position: 'absolute',
            width: '2px',
            height: '100%',
            background: 'linear-gradient(to bottom, #07090A 0%, #1FBEC6 50%, #07090A 100%)',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)'
          }} />
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '2px',
            background: 'linear-gradient(to left, #07090A 0%, #1FBEC6 50%, #07090A 100%)',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)'
          }} />
        </div>
      </div>

      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
        <div style={{ textAlign: 'center', marginBottom: '40px' }}>
          <Typography variant="h4" sx={{ fontFamily: "Circular STD Bold", fontSize: '30px' }}>Why Choose Us?</Typography>
          <Typography variant="body2" sx={{ fontFamily: "Avenir LT STD", color: 'rgba(255,255,255,0.5)', marginTop: '10px' }}>
            We are a dedicated team of experts committed to understanding your<br />unique needs and delivering innovative solutions that drive your growth.
          </Typography>
        </div>
        <Grid container spacing={6}>
          {cardItems.map((result, index) => (
            <Grid size={{ xs: 12, sm: 6 }} key={index} style={{ position: 'relative' }}>
              <Card sx={{ maxWidth: 400, backgroundColor: 'transparent', boxShadow: "none", margin: 'auto' }}>
                <CardActionArea>
                  <CardMedia style={{ textAlign: "left", fontSize: '40px', color: 'white', marginBottom: '10px' }}>{result.icon}</CardMedia>
                  <CardContent sx={{ textAlign: "left", padding: "0" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        marginTop: "10px",
                        fontFamily: "Circular STD Bold",
                        fontSize: "20px",
                        color: '#ffffff',
                      }}
                    >
                      {result.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        marginTop: "10px",
                        fontFamily: "Avenir LT STD",
                        fontSize: "16px",
                        color: 'rgba(255,255,255,0.5)'
                      }}
                    >
                      {result.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Chooseus;
