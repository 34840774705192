import React from 'react';
import { Button, Typography, Container} from '@mui/material';
import Grid from "@mui/material/Grid2";
import './Globalstylingfonts.css'



const HomepageSection = () => {
  return (
    <Container maxWidth="md" sx={{ textAlign: 'center', color: 'white', marginTop: '60px' }}>
      {/* Title */}
      <Typography
        variant="h4"
        sx={{
          fontFamily:'Circular STD Bold',
          marginBottom: '16px',
          color: 'white'
        }}
      >
        Empowering Businesses with Cutting-Edge Tech Solutions That Drive Success
      </Typography>

      {/* Subtitle */}
      <p
        /* variant="body1" */
        style={{
          fontFamily: 'Avenir LT STD',
          fontSize: '16px',
          marginTop: '25px',
          marginBottom: '40px',
          color: 'rgba(255,255,255,0.5)',
        }}
      >
        Providing end-to-end software development services, including Mobile & Web Apps, Cloud Solutions, <br></br>AI, and AR/VR, helping businesses scale with secure, scalable, and cutting-edge technologies.
      </p>

      {/* Action Buttons */}
      <Grid container justifyContent="center" spacing={2}>
        <Grid>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#1FBEC6',
              color: 'black',
              fontFamily:'Circular STD Bold',
              fontSize:'18px',
              padding: '10px 20px',
              borderRadius: '10px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#00a8a8',
              },
            }}
          >
            Get a Free Consultation
          </Button>
        </Grid>
        <Grid >
          <Button
            variant="outlined"
            sx={{
              color: 'white',
              fontFamily:'Circular STD Bold',
              borderColor: 'rgba(255, 255, 255, 0.2)',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              padding: '10px 20px',
              borderRadius: '10px',
              fontSize:'18px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            Talk to Our Experts
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomepageSection;