import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { IoLogoAndroid } from "react-icons/io";
import { SiReact } from "react-icons/si";
import { FaHtml5, FaUnity, FaSwift } from "react-icons/fa6";
import { SiJavascript, SiFlutter } from "react-icons/si";
import { IoLogoVue } from "react-icons/io5";
import { RiDatabase2Line } from "react-icons/ri";
import './Globalstylingfonts.css'
import './SliderStyles.css'

function MultipleItems() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3
  };
  return (
    <div className="slider-container" style={{color:'white', width:'80vw',marginLeft:'10%', marginTop:'10%', fontSize:'40px'}}>
        <h1 style={{fontFamily:'Circular STD Bold', fontSize:"35px", marginBottom: '50px'}}>Technologies We using</h1>
      <Slider {...settings}>
        <div>
          <h1><IoLogoAndroid /></h1>
        </div>
        <div>
          <h1><SiReact /></h1>
        </div>
        <div>
          <h1><FaHtml5 /></h1>
        </div>
        <div>
          <h1><SiJavascript /></h1>
        </div>
        <div>
          <h1><IoLogoVue /></h1>
        </div>
        <div>
          <h1><FaUnity /></h1>
        </div>
        <div>
          <h1><FaSwift /></h1>
        </div>
        <div>
          <h1><SiFlutter /></h1>
        </div>
      </Slider>
    </div>
  );
}

export default MultipleItems;
