import React from 'react';
import {Box} from '@mui/material';
import HomePageHeader from "./HomePageHeader"
import HomepageSection from './HomepageSection'

const HomePage = () => {
  return (
    <div style={{}}>
    <Box
      sx={{
        padding: '0',
        margin: '0',
        background: 'radial-gradient(circle at top, #075a65 0%, #075a65 0%, #000000 55%, #000000 60%)'
      }}
    >
      {/* Full Page Gradient Applied Here */}
      <HomePageHeader />
      <HomepageSection />
    </Box>
    </div>
  );
};

export default HomePage;
