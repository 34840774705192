import React from 'react';
import { Container, Typography, Stepper, Step, StepLabel, StepContent, Paper, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CodeIcon from '@mui/icons-material/Code';
import { StepConnector, styled } from '@mui/material';
import './Globalstylingfonts.css'



const DevelopmentProcess: React.FC = () => {
  const steps = [
    {
     id:1,
      label: '1. Discovery',
      icon: <SearchIcon fontSize="large" />,
      description: 'We begin by understanding your vision and requirements, conducting thorough research to define project goals and objectives.',
    },
    {
        id:2,
      label: '2. Planning',
      icon: <CalendarTodayIcon fontSize="large" />,
      description: 'Our team creates a detailed project roadmap, outlining timelines, resources, and deliverables to ensure a smooth development process.',
    },
    {id:3,
      label: '3. Design',
      icon: <DesignServicesIcon fontSize="large" />,
      description: 'We craft intuitive and engaging user interfaces, focusing on user experience and aligning design elements with your brand identity.',
    },
    {id:4,
      label: '4. Development',
      icon: <CodeIcon fontSize="large" />,
      description: 'Our developers build the software using agile methodologies, ensuring flexibility and adaptability to meet evolving project needs.',
    },
    {id:5,
        label: '5. Testing',
        icon: <DesignServicesIcon fontSize="large" />,
        description: 'We conduct rigorous testing to identify and resolve any issues, ensuring a high-quality, reliable product that meets all specifications.',
      },
      {id:6,
        label: '6. Launch',
        icon: <CodeIcon fontSize="large" />,
        description: 'We launch your project with comprehensive support, ensuring a seamless transition and providing ongoing maintenance to maximize performance.',
      }
  ];

  return (
    <Container maxWidth="md" sx={{backgroundColor:'black'}}>
      <Box mt={8}>
        <Typography variant="h4" align="center" gutterBottom sx={{fontFamily: "Circular STD Bold",}}>
          Development Process
        </Typography>
        <Typography variant="body1" align="center" paragraph sx={{fontFamily: "Avenir LT STD",color: 'rgba(255,255,255,0.5)'}}>
          Our structured development process, from discovery to launch, ensures <br></br>high-quality software tailored to your needs.
        </Typography>
        <Stepper activeStep={-1} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel sx={{color:'white'}} icon={step.icon}>
                <Typography variant="h6" sx={{fontFamily: "Circular STD Bold", color:'white', marginTop:'30px', marginLeft:'60px'}}>{step.label}</Typography>
                <p style={{fontFamily: "Avenir LT STD",color: 'rgba(255,255,255,0.5)', marginLeft:'60px'}}>{step.description}</p>
              </StepLabel>
              <StepContent>
                <Typography variant="body2" sx={{color:'white'}}>
                  {step.description}
                </Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Container>
  );
};

export default DevelopmentProcess;
