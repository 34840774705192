import React from 'react';
import './App.css';
import Expertise from "./pages/Expertise"
import Chooseus from './pages/Chooseus';
import Featuredprojects from './pages/Featuredprojects';
import Joinourproject from './pages/Joinourproject';
import HomePage from './pages/Homepage';
import DevelopmentProcess from './pages/Developmentprocess';
import PartnerLogos from './pages/Greatmindslogos'
import TechnologiesLogo from './pages/TechnologiesLogo'
import Footer from './pages/Footer';
import GetaProposal from './pages/GetaProposal'

function App() {
  return (
    <div className="App">
      <HomePage />
      <Expertise />
      <PartnerLogos />
      <Chooseus />
      <DevelopmentProcess />
      {/* <Featuredprojects /> */}
      <TechnologiesLogo />
      <Joinourproject />
      <GetaProposal />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
