import React from "react";
import { Box, Typography, Button } from "@mui/material";
import "./Globalstylingfonts.css";
import {
  Link,
  IconButton,
  Container,
} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import Grid from "@mui/material/Grid2";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Wiateslogo from "../assets/images/Wiates logo.svg";
import "./Globalstylingfonts.css";

const GetaProposal: React.FC = () => {
  return (
    <div style={{background: 'radial-gradient(circle at bottom, #075a65 0%, #075a65 0%, #000000 50%, #000000 0%)'}}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
        color: "white",
      }}
    >
      <Box
        sx={{
          padding: "30px",
          textAlign: "center",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          borderStyle: "solid",
          borderColor: "rgb(0,200,200,0.1)",
          borderRadius: "15px",
          pointerEvents: "none",
          backgroundColor: "black",
          color: "white",
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: "white", mb: 2, fontFamily: "Circular STD Bold" }}
        >
          Ready to start your project?
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: 4,
            fontFamily: "Avenir LT STD",
            color: "rgba(255,255,255,0.5)",
          }}
        >
          Looking for a tailored solution? Get a quote today and let us help
          bring your project to life.
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#1FBEC6",
            color: "black",
            fontFamily: "Circular STD Bold",
            fontSize: "15px",
            padding: "10px 20px",
            borderRadius: "10px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#14a37f",
            },
          }}
        >
          Get a Proposal
        </Button>
      </Box>
    </Box>
    <Box
      sx={{
        color: "white",
        p: 4,
        mt: 8,
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={3} sx={{marginTop: '50px', marginBottom: '50px'}}>
          <Grid
            size={{ xs: 12, sm: 4 }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start', // Align content to the left
            }}
          >
            <img src={Wiateslogo} style={{ width: "70%", height: "50%" }} />
            <Typography
              variant="body2"
              sx={{
                mt: 2,
                fontFamily: "Avenir LT STD",
                color: "rgba(255,255,255,1)",
                fontSize: "10px",
              }}
            >
              © Copyright 2024 Wiates. All rights reserved.
            </Typography>
          </Grid>

          <Grid
            size={{ xs: 12, sm: 8 }}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end', // Align the remaining content to the right
            }}
          >
            <Grid container spacing={3.8}> {/* Increased spacing */}
              <Grid size={{ xs: 12, sm: 3 }} style={{ textAlign: 'left' }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Circular STD Bold",
                    color: "rgba(255,255,255,1)",
                    fontSize: "15px",
                    marginBottom: '15px',
                  }}
                >
                  Company
                </Typography>
                <Box sx={{marginBottom: '12px'}}>
                  <Link
                    href="#"
                    underline="hover"
                    color="inherit"
                    sx={{
                      fontFamily: "Circular STD Medium",
                      color: "rgba(255,255,255,0.8)",
                      fontSize: "15px",
                    }}
                  >
                    About Us
                  </Link>
                </Box>
                <Box sx={{marginBottom: '12px'}}>
                  <Link
                    href="#"
                    underline="hover"
                    color="inherit"
                    sx={{
                      fontFamily: "Circular STD Medium",
                      color: "rgba(255,255,255,0.8)",
                      fontSize: "15px",
                    }}
                  >
                    Careers
                  </Link>
                </Box>
                <Box sx={{marginBottom: '12px'}}>
                  <Link
                    href="#"
                    underline="hover"
                    color="inherit"
                    sx={{
                      fontFamily: "Circular STD Medium",
                      color: "rgba(255,255,255,0.8)",
                      fontSize: "15px",
                    }}
                  >
                    Terms & Conditions
                  </Link>
                </Box>
                <Box>
                  <Link
                    href="#"
                    underline="hover"
                    color="inherit"
                    sx={{
                      fontFamily: "Circular STD Medium",
                      color: "rgba(255,255,255,0.8)",
                      fontSize: "15px",
                    }}
                  >
                    Privacy Policy
                  </Link>
                </Box>
              </Grid>

              <Grid size={{ xs: 12, sm: 3 }} style={{ textAlign: 'left' }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Circular STD Bold",
                    color: "rgba(255,255,255,1)",
                    fontSize: "15px",
                    marginBottom: '15px',
                  }}
                >
                  Solutions
                </Typography>
                <Box>
                  <Link
                    href="#"
                    underline="hover"
                    color="inherit"
                    sx={{
                      fontFamily: "Circular STD Medium",
                      color: "rgba(255,255,255,0.8)",
                      fontSize: "15px",
                    }}
                  >
                    Projects Worked
                  </Link>
                </Box>
              </Grid>

              <Grid size={{ xs: 12, sm: 3 }} style={{ textAlign: 'left' }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Circular STD Bold",
                    color: "rgba(255,255,255,1)",
                    fontSize: "15px",
                  }}
                >
                  Services
                </Typography>
                {/* You can add services links here */}
              </Grid>

              <Grid size={{ xs: 12, sm: 3 }} style={{ textAlign: 'right' }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Circular STD Bold",
                    color: "rgba(255,255,255,1)",
                    fontSize: "15px",
                    marginRight: '15px',
                    marginBottom: '15px',
                  }}
                >
                  Follow Us On
                </Typography>
                <Box>
                  <IconButton href="#" sx={{ color: "white" }}>
                    <TwitterIcon />
                  </IconButton>
                  <IconButton href="#" sx={{ color: "white" }}>
                    <FacebookIcon />
                  </IconButton>
                  <IconButton href="#" sx={{ color: "white" }}>
                    <InstagramIcon />
                  </IconButton>
                  <IconButton href="#" sx={{ color: "white" }}>
                    <LinkedInIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
    </div>
  );
};

export default GetaProposal;
