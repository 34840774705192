import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Box, Container, CardMedia, IconButton, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Wiateslogo from "../assets/images/Wiates logo.svg";
import './Globalstylingfonts.css';

const HomePageHeader = () => {
  // State for drawer open/close
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Toggle drawer
  const toggleDrawer = (open : any) => (event : any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // Navigation links for desktop and mobile
  const navLinks = ['Services', 'Careers', 'About Us', 'Contact Us'];

  return (
    <>
      <AppBar position="static" color="transparent" elevation={0} sx={{ padding: '20px 0' }}>
        <Container maxWidth="lg">
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* Logo */}
            <CardMedia component="img" image={Wiateslogo} sx={{ width: { xs: '40%', md: '25%' }, height: 'auto' }} />

            {/* Hamburger Menu Icon for Mobile View */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon sx={{ color: 'white' }} />
              </IconButton>
            </Box>

            {/* Desktop Navigation Links */}
            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: '30px', color: 'white' }}>
              {navLinks.map((link) => (
                <Button key={link} color="inherit" sx={{ color: 'rgba(255,255,255,0.8)', textTransform: 'capitalize', fontFamily: 'Avenir LT STD', fontSize: '15px' }}>
                  {link}
                </Button>
              ))}
            </Box>

            {/* Get a Proposal Button (Visible on both Desktop and Mobile) */}
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                color: 'white',
                borderRadius: '10px',
                textTransform: 'none',
                padding: '10px 20px',
                fontFamily: 'Avenir LT STD',
                display: { xs: 'none', md: 'block' }, // Show only on Desktop
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
              }}
            >
              Get a Proposal
            </Button>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {navLinks.map((text) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
            {/* Proposal Button inside Drawer for mobile */}
            <ListItem disablePadding>
              <ListItemButton>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: 'white',
                    borderRadius: '10px',
                    textTransform: 'none',
                    width: '100%',
                    fontFamily: 'Avenir LT STD',
                  }}
                >
                  Get a Proposal
                </Button>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default HomePageHeader;
