import React from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    CardActionArea,
    Container
  } from "@mui/material";
import Grid from "@mui/material/Grid2";
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import { CiMobile3 } from "react-icons/ci";
import { LuBrainCircuit } from "react-icons/lu";
import { MdCloudQueue } from "react-icons/md";
import { MdOutlineGppGood } from "react-icons/md";
import { HiOutlineDatabase } from "react-icons/hi";
import './Globalstylingfonts.css'



// Sample data array with icons
const cardItems = [
  {
    id: 1,
    title: "Mobile Apps",
    description:
      "Build custom, high-performance mobile applications for iOS and Android that provide seamless user experiences and meet your business needs.",
    icon: <CiMobile3 />,
  },
  {
    id: 2,
    title: "Web Apps",
    description:
      "Create scalable and responsive web applications tailored to your goals, with a focus on functionality, user experience, and performance.",
    icon: <HomeIcon />,
  },
  {
    id: 3,
    title: "AI Solutions",
    description:
      "Unlock the potential of artificial intelligence with solutions that automate processes, enhance decision-making, and drive innovation.",
    icon: <LuBrainCircuit />,
  },
  {
    id: 4,
    title: "Cloud Solutions",
    description:
      "Leverage secure, scalable cloud services and infrastructure to optimize performance, reduce costs, and ensure high availability for your business.",
    icon: <MdCloudQueue />,
  },
  {
    id: 5,
    title: "Cybersecurity",
    description:
      "Protect your data, systems, and users with comprehensive cybersecurity solutions, ensuring that your business is safeguarded against threats.",
    icon: <MdOutlineGppGood />,
  },
  {
    id: 6,
    title: "Data Science",
    description:
      "Harness the power of data through advanced analytics, machine learning, and predictive modeling to make data-driven decisions and insights.",
    icon: <HiOutlineDatabase />,
  },
  {
    id: 7,
    title: "ServiceNow Solutions",
    description:
      "Streamline operations with ServiceNow implementation and customization, enhancing workflow efficiency and business process automation.",
    icon: <InfoIcon />,
  },
  {
    id: 8,
    title: "Salesforce",
    description:
      "Maximize your business potential with Salesforce integration, customizations, and automations tailored to your unique needs.",
    icon: <MdCloudQueue />,
  },
  {
    id: 9,
    title: "AR/VR Apps",
    description:
      "Create immersive AR and VR experiences for education, gaming, training, and beyond, delivering cutting-edge solutions for engagement and innovation.",
    icon: <WorkIcon />,
  },
];

const Expertise = () => {
  return (
    <div style={{ backgroundColor: 'black', color:'white', marginTop:'6%', marginBottom: '6%' }}>
      <Container maxWidth="md">
        <div>
            <h4 style={{fontSize:'30px', fontFamily:'Circular STD Bold'}}>Our Expertise</h4>
            <p style={{fontFamily:'Avenir LT STD', margin:'15px', color: 'rgba(255,255,255,0.5)', fontSize:'15px'}}>Right from app development to scaling your organization, we have all the capabilities you need</p>
        </div>
        <Grid container spacing={5} style={{marginTop:"70px"}}>
            {cardItems.map((result,index)=>(
                <Grid size={{xs:12, md:4, lg:4}} key={index}>
                    <Card sx={{ maxWidth: 350, borderStyle:'solid', borderColor:'rgb(0,200,200,0.1)',borderRadius:'15px',pointerEvents: "none", backgroundColor:'black', color:'white', padding:'20px' }}>
                        <CardActionArea>
                            <CardMedia style={{textAlign:"left", fontSize:'25px'}}>{result.icon}</CardMedia>
                            <CardContent sx={{ textAlign: "left", padding: "0" }}>
                    <h3
                      style={{
                        marginTop: "10px",
                        fontFamily: "Circular STD Bold",
                        fontSize: "20px",
                        fontStyle: "Bold",
                      }}
                    >
                      {result.title}
                    </h3>
                    <p
                      style={{
                        marginTop: "10px",
                        fontFamily: "Avenir LT STD",
                        color: 'rgba(255,255,255,0.5)', 
                        fontSize:'15px'
                      }}
                    >
                      {result.description}
                    </p>
                  </CardContent>
                        </CardActionArea>
                    </Card>
                    </Grid>
            ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Expertise;
