import React from 'react';
import {
    Card,
    CardContent,
    CardActionArea,
    Container
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import './Globalstylingfonts.css'

const Items = [{
    id: 1,
    title: "Senior Software Engineer",
    Location: "Location: Remote or On-site",
    description:
        "Leverage secure, scalable cloud services and infrastructure to optimize performance, reduce costs, and ensure high availability for your business.",
},
{
    id: 2,
    title: "UI/UX Designer",
    Location: "Location: Remote or On-site",
    description:
        "Leverage secure, scalable cloud services and infrastructure to optimize performance, reduce costs, and ensure high availability for your business.",
},
{
    id: 3,
    title: "Data Scientist",
    Location: "Location: Remote or On-site",
    description:
        "Leverage secure, scalable cloud services and infrastructure to optimize performance, reduce costs, and ensure high availability for your business."
}]

const Joinourproject = () => {
    return (
        <div>
            <Container maxWidth="md" sx={{ marginTop: '8%' }}>
                <div>
                    <h1 style={{ fontFamily: 'Circular STD Bold' }}>Want to Join Our Team?</h1>
                    <p style={{ fontFamily: 'Avenir LT STD', color: 'rgba(255,255,255,0.5)', marginTop: '15px' }}>
                        Explore open positions and be part of our innovative team, where creativity <br />meets cutting-edge technology.
                    </p>
                </div>
                <Grid container spacing={5}>
                    {Items.map((result, index) => (
                        <Grid size={{ xs: 12, md: 4, lg: 4 }} key={index} style={{ marginTop: '30px' }}>
                            <Card sx={{
                                maxWidth: 280,
                                boxShadow: "none",
                                borderStyle: 'solid',
                                borderColor: 'rgb(0,200,200,0.1)',
                                borderRadius: '15px',
                                backgroundColor: 'black',
                                color: 'white',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%'
                            }}>
                                <CardActionArea sx={{ display: 'flex', flexDirection: 'column', height: '100%', flex: 1, padding: '20px' }}>
                                    {/* Title aligned at the top */}
                                    <CardContent sx={{ textAlign: "left", alignSelf: 'start', padding: "0", flexGrow: 0, marginBottom: "auto" }}>
                                        <h2 style={{
                                            marginTop: "10px",
                                            fontFamily: "Circular STD Bold",
                                            fontSize: "20px",
                                            fontStyle: "Bold",
                                            marginBottom: '15px',
                                        }}>
                                            {result.title}
                                        </h2>
                                    </CardContent>

                                    {/* Content aligned properly */}
                                    <CardContent sx={{ textAlign: "left", alignSelf: 'start', padding: "0 0", flexGrow: 1, marginBottom: "auto" }}>
                                        <p style={{
                                            fontFamily: 'Avenir LT STD',
                                            color: 'rgba(255,255,255,0.5)',
                                            marginBottom: "15px"
                                        }}>
                                            {result.Location}
                                        </p>
                                        <p style={{
                                            fontFamily: 'Avenir LT STD',
                                            color: 'rgba(255,255,255,0.5)'
                                        }}>
                                            {result.description}
                                        </p>
                                    </CardContent>

                                    {/* Button aligned at the bottom */}
                                    <div style={{ marginTop: "auto", alignSelf: 'start', marginBottom: "10px", paddingTop: '10px' }}>
                                        <button style={{
                                            border: 'none',
                                            background: 'none',
                                            color: 'white',
                                            fontFamily: 'Avenir LT STD',
                                        }}>
                                            Apply Now</button><span><ArrowOutwardIcon /></span>
                                    </div>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <button style={{
                    fontFamily: 'Avenir LT STD',
                    borderColor: 'rgb(0,200,200,0.1)',
                    borderRadius: '15px',
                    padding: '20px',
                    backgroundColor: 'black',
                    color: 'white',
                    marginTop: "50px"
                }}>
                    View All Open Positions
                </button>
            </Container>
        </div>
    )
}

export default Joinourproject;
